import { GoogleAuthProvider, OAuthProvider, signInWithPopup, signOut, User } from 'firebase/auth'
import jwtDecode from 'jwt-decode'
import { useRouter } from 'next/router'
import QueryString from 'qs'
import { createContext, useContext, useEffect, useState } from 'react'
import { AuthTokenOtpless, ClearUserState, getCollection, GetProfile, OAuthSignIn, UpdateProfile } from '~/api'
import { firebaseAuth } from '~/config/firebase'

import { Toast, ToastProps } from '~/components/Common/Toast'
import { useLocale } from '~/hooks/useLocale'
import { RequestInterceptor } from '~/lib/axios'
import { setLocale } from '~/lib/dayjs'
import $posthog from '~/lib/posthog'
import $sentry from '~/lib/sentry'
import {
    BusinessAccessT,
    BusinessI,
    HistoryFilterI,
    HistoryI,
    LanguageCode,
    ProductFilterI,
    ProductI,
    ProfileI
} from '~/types'
import { getAccessToken, getCurrentBusiness, getValueByPath, multiPromiseResolver, setCurrentBusiness } from '~/utils'
import { localStore, StoreKey } from '~/utils/local-store'
import { $error, $log } from '~/utils/logger'
import { pageMap } from '~/utils/page'

import Cookies from 'js-cookie'
import { $dayjs } from '~/lib/dayjs'
interface AuthContextProps {
    businesses: BusinessI[]
    profile?: ProfileI
    isAuthenticating: boolean
    isOnboardingProductAvailable: boolean
    loadProfile: () => Promise<ProfileI | undefined>
    authenticate: (token?: string, type?: 'oauth' | 'otpless', action?: 'login' | 'register') => Promise<void>
    currentBusiness: () => BusinessI | undefined
    checkAccess: (access: BusinessAccessT) => boolean
    googleSignIn: (action?: 'sync_email') => Promise<void>
    appleSignIn: (action?: 'sync_email') => Promise<void>
    logout: () => void
}

const AuthContext = createContext<AuthContextProps>({
    businesses: [],
    isAuthenticating: true,
    isOnboardingProductAvailable: false,
    loadProfile: () => Promise.resolve(undefined),
    authenticate: () => Promise.resolve(undefined),
    currentBusiness: () => undefined,
    checkAccess: () => false,
    googleSignIn: () => Promise.resolve(undefined),
    appleSignIn: () => Promise.resolve(undefined),
    logout: () => undefined
})

export const useAuth = () => useContext(AuthContext)

interface AuthProviderProps {
    children: JSX.Element | JSX.Element[]
}

const AuthProvider = ({ children }: AuthProviderProps) => {
    const router = useRouter()

    const [businesses, setBusinesses] = useState<BusinessI[]>([])
    const [profile, setProfile] = useState<ProfileI>()
    const [loading, setLoading] = useState(true)
    const [isOnboardingProductAvailable, setOnboardingProductAvailable] = useState(false)
    const pageProps = pageMap[router.pathname]
    const [toast, setToast] = useState<ToastProps>({
        message: ''
    })

    useEffect(() => {
        checkPlatform()

        authenticate()

        $posthog.init()
    }, [])

    /**
     * Check platform if user visit from webview
     */
    const checkPlatform = () => {
        const query = QueryString.parse(window.location.search, {
            ignoreQueryPrefix: true
        })

        if (query.platform === 'webview') {
            const token = query.token
            const businessId = query.businessId

            if (token) {
                localStore.set(StoreKey.ACCESS_TOKEN, token as string)
            }

            if (businessId) {
                setCurrentBusiness(businessId as string)
            }

            localStore.set(StoreKey.PLATFORM, 'webview')

            setTimeout(() => {
                router.replace(router.pathname, undefined, { shallow: true })
            }, 500)
        }
    }

    /**
     * Google Sign In
     * @param action
     */
    const googleSignIn = async (action?: 'sync_email') => {
        const provider = new GoogleAuthProvider()
        provider.setCustomParameters({ prompt: 'select_account', locale: profile?.language_code || LanguageCode.EN })

        firebaseAuth.languageCode = profile?.language_code || LanguageCode.EN

        if (action !== 'sync_email') {
            $posthog.capture('otp_request', { method: 'google', type: 'sign_in' })
        }

        signInWithPopup(firebaseAuth, provider)
            .then(async (result) => {
                const user = result.user

                if (!user) {
                    throw new Error('User not found')
                }

                if (action === 'sync_email') {
                    syncEmail(user)
                    return
                }

                const token = await user.getIdToken()

                if (token) {
                    authenticate(token, 'oauth')

                    setToast({
                        message: t('common.message.login_success'),
                        open: true,
                        timeOut: 4000,
                        type: 'success'
                    })
                }
            })
            .catch((error) => {
                $error(error)
                setToast({
                    message: 'Google connection failed',
                    open: true,
                    timeOut: 4000,
                    type: 'error'
                })
            })
    }

    /**
     * Apple Sign In
     * @param action
     */
    const appleSignIn = async (action?: 'sync_email') => {
        const provider = new OAuthProvider('apple.com')
        provider.addScope('email')
        provider.addScope('name')
        provider.setCustomParameters({ prompt: 'select_account', locale: profile?.language_code || LanguageCode.EN })

        firebaseAuth.languageCode = profile?.language_code || LanguageCode.EN

        if (action !== 'sync_email') {
            $posthog.capture('otp_request', { method: 'apple', type: 'sign_in' })
        }

        signInWithPopup(firebaseAuth, provider)
            .then(async (result) => {
                const user = result.user

                if (!user) {
                    throw new Error('User not found')
                }

                if (action === 'sync_email') {
                    syncEmail(user)
                    return
                }

                const token = await user.getIdToken()

                if (token) {
                    authenticate(token, 'oauth')
                }
            })
            .catch((error) => {
                $error(error)
                setToast({
                    message: 'Apple connection failed',
                    open: true,
                    timeOut: 4000,
                    type: 'error'
                })
            })
    }

    /**
     * Authenticate the user and protect the route
     */
    const authenticate = async (token?: string, type?: 'oauth' | 'otpless') => {
        let accessToken = localStore.get(StoreKey.ACCESS_TOKEN)
        $log({ token, type, accessToken })

        if (accessToken?.data) {
            RequestInterceptor()

            const authProfile = await loadProfile()

            if (authProfile) {
                const userId = getAccessToken(accessToken?.data)?.id
                $posthog.identify(
                    userId,
                    {
                        'Staff Name': authProfile.name,
                        'Phone Number': authProfile.phoneNumber,
                        Email: authProfile.email
                    },
                    {
                        'Registered Date': $dayjs.utc().format('YYYY-MM-DD HH:mm:ss')
                    }
                )
            }

            redirect(accessToken.data, authProfile)
            return
        }

        if (!token) {
            redirect()
            return
        }

        // for authenticated user
        const { data } = type === 'otpless' ? await AuthTokenOtpless(token) : await OAuthSignIn(token)

        if (!!data) {
            localStore.set(StoreKey.ACCESS_TOKEN, data.token)

            RequestInterceptor()

            accessToken = localStore.get(StoreKey.ACCESS_TOKEN)
            const userId = getAccessToken(accessToken?.data)?.id

            $posthog.identify(
                userId,
                {
                    'Staff Name': data.name,
                    'Phone Number': data.phoneNumber,
                    Email: data.email
                },
                {
                    'Registered Date': $dayjs.utc().format('YYYY-MM-DD HH:mm:ss')
                }
            )
            $posthog.capture('otp_confirm', {
                status: 'success',
                type: data.isNew ? 'new' : 'returning',
                user_id: getAccessToken(data.token)?.id
            })

            $sentry.setUser(userId)
        } else {
            logout()

            if (pageProps.title === 'Login') {
                setToast({
                    message: 'Gagal masuk',
                    open: true,
                    timeOut: 4000,
                    type: 'error'
                })

                return
            }
        }

        const authProfile = await loadProfile()
        redirect(accessToken?.data, authProfile)
    }

    /**
     * Load profile
     * @returns
     */
    const loadProfile = async () => {
        const { data: responseData, error } = await GetProfile()

        if (!!error || !responseData) {
            return
        }

        setProfile(responseData)
        setLocale(responseData.language_code)
        Cookies.set('NEXT_LOCALE', responseData.language_code as string)
        setBusinesses(responseData.businesses)
        return responseData
    }

    /**
     * Get current business data
     * @returns current business
     */
    const currentBusiness = () => {
        const id = getCurrentBusiness()
        return businesses.find((item) => item.businessId === id)
    }

    /**
     * Check if current user has access
     * @param access
     * @returns
     */
    const checkAccess = (access: BusinessAccessT) => {
        const business = currentBusiness()

        if (!business) {
            return false
        }

        if (business.access.includes('all') || ['owner', 'admin'].includes(business.role)) {
            return true
        }

        return business.access.includes(access)
    }

    const redirect = async (accessToken?: any, authProfile?: ProfileI) => {
        if (!pageProps) {
            setLoading(false)
            return
        }

        if (!accessToken || !authProfile) {
            ClearUserState()

            if (pageProps.auth) {
                window.location.href = '/login'
                return
            }

            setLoading(false)
            return
        }

        $log({ accessToken, authProfile, isPageAuth: pageProps.auth })

        try {
            jwtDecode(accessToken || '')

            const { is_onboard } = router.query
            const checkOnboarding = await isNeedOnboarding(authProfile)

            $log({ is_onboard, checkOnboarding })

            if (!pageProps.auth) {
                if (checkOnboarding) {
                    window.location.href = '/onboarding'
                    return
                }

                window.location.href = '/business'
                return
            }

            if (!is_onboard) {
                if (checkOnboarding) {
                    router.push('/onboarding').then(() => {
                        setLoading(false)
                    })
                    return
                }

                if (pageProps.title === 'onboarding.welcome') {
                    router.push('/business').then(() => {
                        setLoading(false)
                    })
                    return
                }
            }

            setLoading(false)
        } catch (err) {
            $error(err)
            ClearUserState()
            logout()

            if (pageProps.auth) {
                window.location.href = '/login'
                return
            }

            setLoading(false)
        }
    }

    const isNeedOnboarding = async (authProfile?: ProfileI) => {
        $log({ authProfile })
        if (!authProfile) {
            return false
        }

        const selectedBusiness = authProfile.businesses.find((item) => item.role === 'admin' || item.role === 'owner')
        const businessWithAdminRole = authProfile.businesses.filter(
            (item) => item.role === 'admin' || item.role === 'owner'
        )

        if (!selectedBusiness || businessWithAdminRole.length !== 1) {
            return false
        }

        const onboardStep = localStore.get(StoreKey.ONBOARD_STEP)

        if (onboardStep?.data === '1') {
            return false
        }

        const [{ value: dataProducts }, { value: dataHistory }] = await multiPromiseResolver([
            getCollection<ProductI, ProductFilterI, 'products'>(`/business/${selectedBusiness.businessId}/product`, {
                limit: 1,
                offset: 0
            }),
            getCollection<HistoryI, HistoryFilterI, 'histories'>(
                `/business/${selectedBusiness.businessId}/product/stock/history`,
                {
                    limit: 1,
                    offset: 0
                },
                'v2'
            )
        ])

        if (dataProducts?.meta.total !== 0) {
            setOnboardingProductAvailable(true)

            if (dataHistory?.histories?.length !== 0) {
                localStore.set(StoreKey.ONBOARD_STEP, '1')
                return false
            }
        }

        return true
    }

    const syncEmail = async (user: User | null) => {
        try {
            if (!user || !user.email) {
                throw new Error('User not found')
            }

            const { error } = await UpdateProfile({
                name: profile?.name || user.displayName || '',
                imageUrl: profile?.imageUrl || user.photoURL || '',
                phoneNumber: profile?.phoneNumber || '',
                email: user.email
            })

            if (!!error) {
                throw new Error(error.message)
            }

            setToast({
                message: 'Sinkronisasi email berhasil',
                open: true,
                timeOut: 4000,
                type: 'success'
            })

            window.location.reload()
        } catch (e) {
            $error(e)
            setToast({
                message: 'Gagal sinkronisasi email',
                open: true,
                timeOut: 4000,
                type: 'error'
            })
        }
    }

    const logout = () => {
        signOut(firebaseAuth)
    }

    const t = (key: string) => {
        const text = getValueByPath(langData, key)

        return text || key
    }

    const langData = useLocale(profile?.language_code || LanguageCode.EN)

    return (
        <>
            <AuthContext.Provider
                value={{
                    businesses,
                    profile,
                    isAuthenticating: loading,
                    isOnboardingProductAvailable,
                    loadProfile,
                    authenticate,
                    currentBusiness,
                    checkAccess,
                    googleSignIn,
                    appleSignIn,
                    logout
                }}>
                {children}
            </AuthContext.Provider>

            <Toast
                {...toast}
                onClose={() => {
                    setToast({
                        message: ''
                    })
                }}></Toast>
        </>
    )
}

export default AuthProvider
