import axios from 'axios'

import { AppProps } from 'next/app'

import AuthProvider from '~/components/Auth/AuthProvider'
import GlobalDataProvider from '~/components/Common/GlobalDataProvider'
import { useInterval } from '~/hooks'

import { SubscriptionProvider } from '~/components/Subscription/SubscriptionProvider'
import '../styles/components/calender.css'
import '../styles/globals.css'

import { SpeedInsights } from '@vercel/speed-insights/next'
import { Meta } from '~/components/Common/Meta'
import { LanguageProvider } from '~/components/Language/LanguageProvider'
import { Toaster } from '~/components/ui/toaster'
import { useSafeDom } from '~/hooks/useSafeDom'

const MyApp = ({ Component, pageProps }: AppProps) => {
    useSafeDom()

    useInterval(
        async () => {
            const { buildId } = await axios.get('/api/build-id').then((res) => res.data)

            if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
                window.location.reload()
            }
        },
        { interval: 5000 * 60 }
    )
    return (
        <>
            <Meta>
                <meta name="application-name" content="kelola.co" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                <meta name="apple-mobile-web-app-title" content="Kelola" />
                <meta name="format-detection" content="telephone=no" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="msapplication-TileColor" content="#0051E0" />
                <meta name="msapplication-tap-highlight" content="no" />
                <meta name="theme-color" content="#000000" />

                <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="mask-icon" href="/icons/apple-touch-icon.png" color="#0051E0" />
                <link rel="shortcut icon" href="/favicon.ico" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:url" content="https://web.kelola.co" />
                <meta name="twitter:image" content="https://web.kelola.co/images/aplikasi-stok-barang-terbaik.png" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Kelola" />
                <meta property="og:url" content="https://web.kelola.co" />
                <meta property="og:image" content="https://web.kelola.co/images/aplikasi-stok-barang-terbaik.png" />
            </Meta>
            <SpeedInsights />

            {/* <Script src="https://js.durianpay.id/0.1.39/durianpay.min.js"></Script> */}
            <Toaster></Toaster>
            <AuthProvider>
                <LanguageProvider>
                    <SubscriptionProvider>
                        <GlobalDataProvider>
                            <Component {...pageProps} />
                        </GlobalDataProvider>
                    </SubscriptionProvider>
                </LanguageProvider>
            </AuthProvider>
        </>
    )
}

export default MyApp
