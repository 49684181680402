// Create a file in your project: hooks/useSafeDOM.js
import { useEffect } from 'react'

export function useSafeDom() {
    useEffect(() => {
        // Store the original method
        const originalRemoveChild = Node.prototype.removeChild

        // Replace with our safer version
        Node.prototype.removeChild = function <T extends Node>(child: T): T {
            // Only proceed if the child is actually contained in this node
            if (child && this.contains(child)) {
                return originalRemoveChild.call(this, child) as T
            }
            console.warn('Prevented removeChild error with non-child node')
            return child // Return the child even if not removed
        }

        // Cleanup function restores original behavior
        return () => {
            Node.prototype.removeChild = originalRemoveChild
        }
    }, [])
}
