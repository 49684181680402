import { useEffect } from 'react'
import { responsiveBreakpointMap } from '~/constants'
import { DesktopPrompt } from '../DesktopPrompt'

interface AuthLayoutProps {
    children: JSX.Element | JSX.Element[]
    showMobile?: boolean
}

export const AuthLayout = ({ children, showMobile }: AuthLayoutProps) => {
    useEffect(() => {
        const img = new Image()
        img.src = '/images/kelola-hero.webp'
    }, [])

    if (window.innerWidth < responsiveBreakpointMap.sm && !showMobile) {
        return (
            <>
                <DesktopPrompt></DesktopPrompt>
            </>
        )
    }

    return (
        <>
            <main className="w-full h-full grid grid-cols-1 md:grid-cols-2">
                <div className="max-h-full w-full overflow-auto flex justify-center items-center">{children}</div>
                <div className="hidden md:block max-h-full w-full">
                    <div
                        className="bg-neutrals-100 w-full h-full bg-cover bg-center"
                        style={{ backgroundImage: `url(/images/kelola-hero.webp)` }}></div>
                </div>
            </main>
        </>
    )
}
