import { AnimatePresence, motion } from 'framer-motion'
import { Button } from '~/components/Common/Button'
import Icon from '~/components/Icon'
import { useLanguage } from '../Language/LanguageProvider'
import { SubscriptionIcon } from './SubscriptionIcon'

interface SubscriptionPaywallProps {
    show?: boolean
    onClose?: () => void
}

export const SubscriptionPaywall = ({ show = false, onClose = () => false }: SubscriptionPaywallProps) => {
    const { t, langData } = useLanguage()
    return (
        <AnimatePresence>
            {show && (
                <motion.div
                    className="fixed left-0 top-0 w-full h-full bg-white overflow-auto px-4 py-4 sm:py-8 sm:px-6 z-50"
                    initial={{
                        scale: 0.5,
                        opacity: 0.8
                    }}
                    animate={{
                        scale: 1,
                        opacity: 1
                    }}
                    exit={{
                        opacity: 0
                    }}>
                    <div className="absolute top-4 right-4 sm:top-8 sm:right-6 flex items-center justify-center">
                        <Icon size="lg" name="x" onClick={onClose}></Icon>
                    </div>
                    <div className="grid grid-cols-1 gap-10 w-full">
                        <div className="flex items-center justify-center">
                            <SubscriptionIcon></SubscriptionIcon>
                        </div>
                        <div className="grid grid-cols-1 gap-4 w-full max-w-lg mx-auto">
                            <h2 className="font-bold text-xl sm:text-2xl text-center">
                                {langData.subscription.paywall_large.title}
                            </h2>
                            <p className="text-center sm:text-base text-sm text-neutrals-500">
                                {langData.subscription.paywall_large.description}
                            </p>
                            <div className="flex items-center justify-center flex-col">
                                <a
                                    className="w-full max-w-sm mb-2"
                                    href="https://kelola.co"
                                    target="_blank"
                                    rel="noreferrer">
                                    <Button
                                        text={langData.subscription.paywall_large.button}
                                        fullWidth
                                        icon={<Icon size="xl" name="premium-outline"></Icon>}></Button>
                                </a>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 mx-auto max-w-5xl">
                            {langData.subscription.paywall_large.features.map((feature: any, index: any) => (
                                <div className="grid grid-cols-1 gap-1 items-start" key={index}>
                                    <h3 className="font-bold">{feature.title}</h3>
                                    <p className="text-sm text-neutrals-500">{feature.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
