import {
    CustomerReportFilterIQueryI,
    ProductFilterQueryI,
    ProductI,
    StockReportFilterQueryI,
    StockUpdateI
} from '~/types'
import { $error, $log } from './logger'

interface StoreI<T = any, K extends keyof T = any> {
    id: string
    createdAt: number
    updatedAt: number
    collection: StoreCollection<T, K>[]
}

interface StoreCollection<T, K extends keyof T> {
    key: K
    value: {
        data: T[K]
        createdAt: number
        updatedAt: number
        expiredAt?: number
        token?: string
    }
}

/**
 * Local store
 */
class LocalStore<T> {
    version: number
    id: string
    name: string
    timestamp: number

    /**
     * Constructor
     * @param {number} version
     * @param {string} id
     */
    constructor(version: number, id: string) {
        this.version = version
        this.id = id
        this.name = `app-store-v${this.version}`
        this.timestamp = Math.floor(new Date().getTime() / 1000)
    }

    /**
     * Get store data from local storage
     * @return {StoreI[]}
     */
    getStore(): StoreI[] {
        const data = localStorage.getItem(this.name)

        if (!data) {
            localStorage.setItem(this.name, JSON.stringify([]))
            return []
        }

        return JSON.parse(data)
    }

    /**
     * Set store data into local storage
     * @param {StoreI[]} data
     */
    setStore(data: StoreI[]) {
        $log('setStore', data)
        localStorage.setItem(this.name, JSON.stringify(data))
    }

    /**
     * Get store item from store
     * @return {StoreI | null}
     */
    getStoreItem(): StoreI | null {
        const store = this.getStore()
        const data = store.find((item) => item.id === this.id)
        return data ? data : null
    }

    /**
     * Get item collection
     * @param {K} key
     * @param {boolean} withTimestamp
     * @return {T | null}
     */
    get<K extends keyof T>(key: K): StoreCollection<T, K>['value'] | null {
        const storeItem = this.getStoreItem()

        if (!storeItem) {
            return null
        }

        const collection = storeItem.collection.find((item) => item.key === key)

        if (!collection) {
            return null
        }

        if (this.getExpiry(collection.value.expiredAt)) {
            this.remove(key)
            return null
        }

        return collection.value
    }

    /**
     * Set item collection
     * @param {K} key
     * @param {T} data
     * @param {string} expiredAt - days or hours ex: 1d, 24h. Optional
     */
    set<K extends keyof T>(key: K, data: T[K], expiredAt?: string, token?: string) {
        const store = this.getStore()
        const transformExpiredAt = expiredAt ? this.getExpiredAt(expiredAt, this.timestamp) : undefined

        // if store item not exist add as new entry else update
        const storeItemIndex = store.findIndex((item) => item.id === this.id)
        if (storeItemIndex === -1) {
            const collection: StoreCollection<T, K>[] = [
                {
                    key,
                    value: {
                        data,
                        createdAt: this.timestamp,
                        updatedAt: this.timestamp,
                        expiredAt: transformExpiredAt,
                        token
                    }
                }
            ]

            store.push({
                id: this.id,
                createdAt: this.timestamp,
                updatedAt: this.timestamp,
                collection
            })
            localStorage.setItem(this.name, JSON.stringify(store))

            return
        }

        const storeItem = store[storeItemIndex]

        // check if collection item exist if not add as new entry else update
        const collectionIndex = storeItem.collection.findIndex((item) => item.key === key)
        if (collectionIndex === -1) {
            storeItem.collection.push({
                key,
                value: {
                    data,
                    createdAt: this.timestamp,
                    updatedAt: this.timestamp,
                    expiredAt: transformExpiredAt,
                    token
                }
            })

            this.setStore(store)
            return
        }

        storeItem.updatedAt = this.timestamp
        storeItem.collection[collectionIndex].value = {
            ...storeItem.collection[collectionIndex].value,
            data,
            updatedAt: this.timestamp,
            expiredAt: transformExpiredAt,
            token
        }

        this.setStore(store)
    }

    /**
     * Remove collection item
     * @param {K} key
     * @return {boolean}
     */
    remove<K extends keyof T>(key: K): boolean {
        const store = this.getStore()

        const storeItemIndex = store.findIndex((item) => item.id === this.id)

        if (storeItemIndex === -1) {
            return false
        }

        // if collection length is more than 1 remove the item else remove the whole store item
        if (store[storeItemIndex].collection.length > 1) {
            store[storeItemIndex].collection = store[storeItemIndex].collection.filter((item) => item.key !== key)
            store[storeItemIndex].updatedAt = this.timestamp
            this.setStore(store)
            return true
        }

        this.setStore(store.filter((item) => item.id !== this.id))
        return true
    }

    /**
     * Clean previous version of the store
     */
    clean() {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i)
            if (!key) continue

            if (key.startsWith('app-store-v') && !key.endsWith(`-v${this.version}`)) {
                localStorage.removeItem(key)
            }
        }
    }

    /**
     * Check if data is expired
     * @param {number} expiredAt unix timestamp
     * @return {boolean}
     */
    getExpiry(expiredAt?: number) {
        if (!expiredAt) {
            return false
        }
        return this.timestamp > expiredAt
    }

    /**
     * Check if data is expired
     * @param {string} value string
     * @param {number} currentTimeStamp unix timestamp
     * @return {number | undefined} expiredAtTimeStamp unix
     */
    getExpiredAt = (value: string, currentTimeStamp: number) => {
        try {
            const valueExtracted = Number(value.replace(/m|h|d|/g, ''))
            let timeToAdd = 0
            if (value.endsWith('m')) {
                timeToAdd = valueExtracted * 60
            } else if (value.endsWith('h')) {
                timeToAdd = valueExtracted * 60 * 60
            } else if (value.endsWith('d')) {
                timeToAdd = valueExtracted * 24 * 60 * 60
            } else {
                throw Error()
            }
            return currentTimeStamp + timeToAdd
        } catch (e) {
            $error('Unexpected expireAt format')
            return undefined
        }
    }
}

export default LocalStore

export enum StoreKey {
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    SELECTED_BUSINESS = 'SELECTED_BUSINESS',
    SELECTED_PRODUCT = 'SELECTED_PRODUCT',
    ONBOARD_STEP = 'ONBOARD_STEP',
    OTPLESS_TOKEN = 'OTPLESS_TOKEN',
    OAUTH_TOKEN = 'OAUTH_TOKEN',
    PLATFORM = 'PLATFORM',
    PRODUCT_FILTER = 'PRODUCT_FILTER',
    STOCK_REPORT_FILTER = 'STOCK_REPORT_FILTER',
    CUSTOMER_REPORT_FILTER = 'CUSTOMER_REPORT_FILTER',
    STOCK_UPDATE_DRAFT = 'STOCK_UPDATE_DRAFT'
}

export type StoreKeyTypeMap = {
    [StoreKey.ACCESS_TOKEN]: string
    [StoreKey.SELECTED_BUSINESS]: string
    [StoreKey.SELECTED_PRODUCT]: ProductI
    [StoreKey.ONBOARD_STEP]: string
    [StoreKey.OTPLESS_TOKEN]: string
    [StoreKey.OAUTH_TOKEN]: string
    [StoreKey.PLATFORM]: string
    [key: `${StoreKey.PRODUCT_FILTER}-${string}`]: ProductFilterQueryI
    [key: `${StoreKey.STOCK_REPORT_FILTER}-${string}`]: StockReportFilterQueryI
    [key: `${StoreKey.CUSTOMER_REPORT_FILTER}-${string}`]: CustomerReportFilterIQueryI
    [key: `${StoreKey.STOCK_UPDATE_DRAFT}-${string}`]: StockUpdateI
}

export const localStore = new LocalStore<StoreKeyTypeMap>(1, 'app')
